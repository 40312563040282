import { HeadingStyleType, HeadingType } from 'components/Heading';
import { ReactNode, useState } from 'react';
import {
  AccordionWrapper,
  Icon,
  ItemSummary,
  ItemDetails,
  ItemWrapper,
  ItemSummaryHeading,
  IconWrapper,
  Wrapper,
} from './styles';

type AccordionItemData = {
  summary: string;
  details: ReactNode;
};

type AccordionProps = {
  openItem: ReactNode | null;
  items: AccordionItemData[];
};

const Accordion = ({ items }: AccordionProps) => {
  const [openItem, setOpenItem] = useState<number | null>(null);

  const onClick = (id: number) => {
    if (openItem === id) {
      setOpenItem(null);
      return;
    }

    setOpenItem(id);
  };

  return (
    <AccordionWrapper>
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          id={index}
          summary={item.summary}
          details={item.details}
          isOpen={index === openItem}
          onClick={(id) => onClick(id)}
        />
      ))}
    </AccordionWrapper>
  );
};

type AccordionItemProps = {
  id: number;
  summary: string;
  details: ReactNode;
  isOpen: boolean;
  onClick: (id: number) => void;
};

const AccordionItem = ({ id, summary, details, isOpen, onClick }: AccordionItemProps) => {
  return (
    <ItemWrapper isOpen={isOpen}>
      <ItemSummary onClick={() => onClick(id)}>
        <ItemSummaryHeading
          styleType={HeadingStyleType.Heading7}
          headingType={HeadingType.H2}
          upperCase
        >
          {summary}
        </ItemSummaryHeading>
        <IconWrapper isOpen={isOpen}>
          <Wrapper isOpen={isOpen}>
            <Icon />
          </Wrapper>
        </IconWrapper>
      </ItemSummary>
      <ItemDetails isOpen={isOpen}>{details}</ItemDetails>
    </ItemWrapper>
  );
};

export { Accordion, AccordionItem };
