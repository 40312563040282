import Spinner from 'components/Spinner';
import { ParagraphStyleType } from 'components/Paragraph';
import { LoadingWrapper, StyledParagraph } from './styles';

type LoadingProps = {
  text?: string;
};
function Loading({ text, ...rest }: LoadingProps) {
  return (
    <LoadingWrapper {...rest}>
      <Spinner />
      <StyledParagraph styleType={ParagraphStyleType.body18}>{text}</StyledParagraph>
    </LoadingWrapper>
  );
}

export default Loading;
