import { Button, ExternalLink, Paragraph } from 'component-library';
import { useTranslation } from 'react-i18next';
import { MessageContainer, MessageWrapper } from './styles';
import { navigateToPortalPickups } from './utils';
import { useFlag } from 'core';

export const OPV2Message = () => {
  const { t } = useTranslation();

  const ffOPV2Modal = useFlag('pwa-opv2-modal');

  if (!ffOPV2Modal) {
    return null;
  }

  return (
    <MessageWrapper>
      <MessageContainer>
        <Paragraph variant="large" bold>
          {t('order.pickups.on.portal.confirmation.title')}
        </Paragraph>
        <Paragraph mb="xsmall" variant="large">
          {t('order.pickups.on.portal.confirmation.text')}
        </Paragraph>
        <Button
          variant="secondary"
          fullWidth={true}
          onClick={() => {
            navigateToPortalPickups();
          }}
          icon={<ExternalLink />}
          iconPlacement="after"
        >
          {t('order.pickups.on.portal.confirmation.cta')}
        </Button>
      </MessageContainer>
    </MessageWrapper>
  );
};
